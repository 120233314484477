import React, { useState, useEffect } from 'react';
import BScroll from 'better-scroll';
import { Loading, Toast, Navbar, Tabbar } from '../../components';
import API from '../../api';
import './style.less';

const CATEGORY = ['0KDPOFVKZG1WMTWB3B32', '0KDPOGOU321XPTWB3B30'];

const Info = (props) => {
  const [currentIndex, setCuttentIndex] = useState(0);
  const [lastId, setLastId] = useState('');
  const [page, setPage] = useState(1);
  const [more, setMore] = useState(true);
  const [bScroll, setBScroll] = useState(null);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    // BScroll需要在数据渲染完成后再初始化
    if (!bScroll && dataList.length) {
      const wrapper = document.getElementById('scroll-wrapper');
      const scroll = new BScroll(wrapper, {
        click: true,
        mouseWheel: true,
      });
      setBScroll(scroll);
      // 下拉刷新
      scroll.on('touchEnd', (pos) => {
        //判断用户的下拉动作
        if (pos.y > 50) {
          setLastId('');
          setPage(1);
        }
      });
      // 加载更多
      scroll.on('scrollEnd', () => {
        //判断是否滑动到了底部
        if (scroll.y <= scroll.maxScrollY + 100) {
          setPage((prevPage) => prevPage + 1);
        }
      });
    }

    // 数据发生改变时，需要refresh，使用定时器延迟是保证数据渲染完成
    if (bScroll) {
      setTimeout(() => {
        bScroll.refresh();
      }, 40);
    }
  }, [dataList]);

  useEffect(() => {
    // 获取资讯列表
    const getInformation = async () => {
      try {
        const res = await API.information({
          // params: `{"registrationId":"12345678-5678-1234-8765-123456789012","pageSize":10,"id":"${lastId}","categoryId":"${CATEGORY[currentIndex]}"}`,
          categoryId: CATEGORY[currentIndex],
          pageSize: 10,
          pageNum: page,
        });
        if (res.code === 200) {
          const { data = {} } = res;
          let { records: list = [], current, pages } = data;
          list = list.map((item) => {
            const { authorName = '', coverJson = '' } = item;
            item.auth = authorName ? authorName : '';
            const str = coverJson ? coverJson.slice(2, -2) : '';
            const id = str.split(',')[0];
            item.coverImg = `https://app2s.shuibeidianjin.com:444/file/image.jpeg?id=${id}`;
            return item;
          });
          if (current < pages) {
            setMore(true);
          } else {
            setMore(false);
          }
          // setMore(data.more); // 是否还有更多数据
          if (page === 1) {
            setDataList(list);
            if (bScroll) {
              // 滚动列表到顶部
              bScroll.scrollTo(0, 0);
            }
          } else {
            setDataList(dataList.concat(list));
          }
        }
      } catch (error) {
        console.log(error);
        Toast.error('获取资讯列表异常');
      }
    };

    if (more) {
      getInformation();
    }
  }, [page, more, currentIndex]);

  const switchTab = (index) => {
    setCuttentIndex(index);
    setLastId('');
    setPage(0);
    setMore(true);
    setDataList([]);
  };

  const handleItemClick = (id) => {
    props.history.push(`/infoDetail/${id}`);
  };

  return (
    <div className="info-page">
      <Tabbar />
      <Navbar />
      <div className="tabs">
        <div
          className={`item ${currentIndex === 0 ? 'active' : ''}`}
          onClick={() => switchTab(0)}
        >
          观点
        </div>
        <div
          className={`item ${currentIndex === 1 ? 'active' : ''}`}
          onClick={() => switchTab(1)}
        >
          要闻
        </div>
      </div>
      <div id="scroll-wrapper">
        <div className="list">
          {dataList.map((item) => (
            <div
              className="item"
              key={item.id}
              onClick={() => handleItemClick(item.id)}
            >
              <div className="header">
                <div className="left">
                  <img
                    className="avatar"
                    src={
                      item.avatar ||
                      'http://www.shuibeidianjin.com/images/avatar.jpeg'
                    }
                  />
                  <span className="name">{item.auth}</span>

                </div>
                <div className="right">{item.publishOn}</div>
              </div>
              <div className="body">
                <div className="left">
                  <p className={`title ${item.markRead ? 'read' : ''}`}>
                    {item.title}
                  </p>
                  <div className="count">
                    <img
                      className="count-icon"
                      src="http://www.shuibeidianjin.com/images/read.png"
                    />
                    <span className="count-number">{item.read}</span>
                  </div>
                </div>
                <div
                  className="right"
                  style={{
                    backgroundImage: `url(${item.coverImg})`,
                  }}
                />
              </div>
            </div>
          ))}
          {!more ? (
            <div className="more">我是有底线的</div>
          ) : (
            <div className="more">数据加载中...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
