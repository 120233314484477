import React from 'react';
import {
  Route,
  Switch,
  BrowserRouter,
  HashRouter,
  withRouter,
  Redirect,
} from 'react-router-dom';
import NotLiveRoute from 'react-live-route';

import Index from './pages/index'; // 行情
import Info from './pages/info'; // 咨询
import About from './pages/about'; // 公司介绍
import Address from './pages/address'; // 联系我们
import InfoDetail from './pages/infoDetail'; // 咨询详情
import QuotesDetail from './pages/quotesDetail'; // 行情详情

const LiveRoute = withRouter(NotLiveRoute);

const App = () => {
  return (
    <HashRouter>
      <React.Fragment>
        <Switch>
          <Route path="/index" />
          <Route path="/info" />
          <Route path="/about" component={About} />
          <Route path="/address" component={Address} />
          <Route path="/infoDetail/:id" component={InfoDetail} />
          <Route path="/quotesDetail/:id" component={QuotesDetail} />
          <Route exact path="/" render={() => <Redirect to="/index" />} />
        </Switch>
        {/* 缓存行情页 */}
        <LiveRoute
          path="/index"
          alwaysLive={true}
          component={Index}
          onHide={() => {
            const indexPage = document.getElementById('index-page');
            indexPage.style.display = 'none';
          }}
          onReappear={() => {
            const indexPage = document.getElementById('index-page');
            indexPage.style.display = 'block';
          }}
        />
        {/* 缓存咨询页 */}
        <LiveRoute path="/info" livePath="/infoDetail/:id" component={Info} />
      </React.Fragment>
    </HashRouter>
  );
};

export default App;
