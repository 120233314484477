import React, {
  useState,
  useEffect
} from 'react';
import {
  Toast,
  Navbar,
  Tabbar
} from '../../components';
import API from '../../api';
import './style.less';

const About = (props) => {
  const [id] = useState(props.match.params.id);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    // 获取咨询详情
    const getInformationDetail = async () => {
      try {
        const res = await API.informationDetail(id);
        if (res.code === 200) {
          let {
            data: detail = {}
          } = res;
          const {
            authorName = ''
          } = detail;
          detail.auth = authorName ? authorName: '';
          setDetail(detail);
        }
      } catch (error) {
        console.log(error);
        Toast.error('获取资讯详情异常');
      }
    };

    getInformationDetail();
  }, [id]);
  return ( <
    div className = "info-detail-page" >
    <
    Navbar showBack / >
    <
    Tabbar active = {
      2
    }
    /> <
    p className = "info-detail-title" > {
      detail.title
    } < /p> <
    div className = "info-detail-header" >
    <
    div className = "left" >
    <
    img className = "avatar"
    src = {
      detail.avatar ||
      'http://www.shuibeidianjin.com/images/avatar.jpeg'
    }
    /> <
    span className = "name" > {
      detail.auth
    } < /span>  <
    /div> <
    div className = "right" > {
      detail.publishOn
    } < /div> <
    /div> <
    div className = "info-detail-content"
    dangerouslySetInnerHTML = {
      {
        __html: detail.content
      }
    }
    /> <
    /div>
  );
};

export default About;