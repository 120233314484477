import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navbar, Tabbar, Toast } from './../../components';
import './style.less';

const About = () => {
  const [detail, setDetail] = useState('');

  useEffect(() => {
    // 获取公司介绍
    const getAbout = async () => {
      try {
        const res = await axios.get(
          'https://min-app.shuibeidianjin.com:10101/api/sjdj-mp/common/getAboutDj'
        );
        if (res.status === 200) {
          const { code, data = {} } = res.data;
          if (code === 200) {
            setDetail(data);
          }
        }
      } catch (error) {
        console.log(error);
        Toast.error('获取资讯详情异常');
      }
    };

    getAbout();
  }, []);

  if (!detail) return null;

  return (
    <div className="about-page">
      <Tabbar />
      <Navbar />
      <div
        className="about-page-content"
        dangerouslySetInnerHTML={{ __html: detail }}
      />
      {/* <img
        className="logo"
        src="http://www.shuibeidianjin.com/images/logo2.png"
      />
      <p className="desc">
        深圳市水贝点金投资有限公司于2007年在深圳成立，是国内贵金属一体化专业服务平台，深得珠宝企业和珠宝商以及贵金属投资者信赖。投资者提供最有价值的实用参考指南，提供全方位交流，覆盖行情、报价、财经、论坛等专业领域，通过互联网金融，依靠移动互联和大数据形成功能化的贵金属业态和服务体系。致力于为行业提供全方位的服务，将其带入每个行业、每个组织、构建属于我们的行业生态链。
      </p>
      <p className="desc">
        水贝点金主张开放、安全、权威、创新，促进贵金属行业的专业性，我们为客户推出了新的保证金投资业务，可以让客户以最低的成本赚取最大的利润，并为每一位客户提供了入库服务，全面保障客户的权益。
      </p>
      <p className="desc">
        随着水贝点金客户群体日益庞大，物理世界与数字世界的加速融合，水贝点金也逐渐迈向更为智能化的体验，水贝点金公众号报价功能和小程序就是为此而生，为广大客户提供了更为便捷的行业资讯与知识，更为安全的用户信息管理，我们也将不断进步，全面实现实时在线、自然交互、懂你所思、服务直达。
      </p>
      <p className="desc">
        水贝点金将努力联结行业各节点，促进黄金产业发展，继续推动行业普及化，深刻洞察客户所需、敏捷创新，实现一致性和以人为本的业务体验。努力为大家创造一个丰富的行业生态链。
      </p> */}
    </div>
  );
};

export default About;
