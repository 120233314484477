import axios from 'axios';
import qs from 'qs';

const instance = axios.create({
  timeout: 30000,
});

instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  get(url, params) {
    return instance.get(url, {
      params,
    });
  },
  post(url, data) {
    return instance.post(url, qs.stringify(data));
  },
};
