import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.less';

const Navbar = (props) => {
  const { showBack = false, history, renderRight = () => {} } = props;
  return (
    <div className="navbar-component">
      <div className="navbar-content">
        <div className="navbar-left">
          {!!showBack && (
            <img
              className="navbar-back"
              src="http://www.shuibeidianjin.com/images/back.png"
              onClick={() => history.goBack()}
            />
          )}
          <img
            className="navbar-logo"
            src="http://www.shuibeidianjin.com/images/logo.png"
          />
        </div>
        <div className="navbar-right">{renderRight()}</div>
      </div>
    </div>
  );
};

Navbar.prototype.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default React.memo(withRouter(Navbar));
