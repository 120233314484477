import React, { useState, useEffect } from 'react';
import API from '../../api';
import { Navbar, Tabbar } from '../../components';
import { useInterval } from '../../hooks';
import './style.less';

const splitPrice = (number, dec) => {
  const price = String(number.toFixed(dec));
  return [price.slice(0, -2), price.slice(-2)];
};

const Index = (props) => {
  const [nowTime, setNowTime] = useState('');
  const [isOpening, setIsOpening] = useState(false);
  const [list, setList] = useState([]);
  const [phone, setPhone] = useState('');

  const getLatestMarketPrice = async () => {
    try {
      const res = await API.latestMarketPrice();
      const { data = {} } = res;
      if (data.success) {
        const { nowTime, isOpening, marketPrices } = data;

        setNowTime(nowTime);
        setIsOpening(isOpening);
        setList((prevList) => {
          for (let i = 0; i < marketPrices.length; i++) {
            if (prevList.length) {
              marketPrices[i].init = 1;
              marketPrices[i].sellRise =
                marketPrices[i].sellPrice - prevList[i].sellPrice;
              marketPrices[i].buyRise =
                marketPrices[i].buyPrice - prevList[i].buyPrice;
              marketPrices[i].sellCloseRise =
                marketPrices[i].sellPrice - prevList[i].sellClosePrice;
              marketPrices[i].buyCloseRise =
                marketPrices[i].buyPrice - prevList[i].buyClosePrice;
            } else {
              marketPrices[i].init = 0; // 用于设置初始颜色为红色
            }
          }
          return marketPrices;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestMarketPrice();
  }, []);

  useInterval(() => {
    getLatestMarketPrice();
  }, 1000);

  useEffect(() => {
    // 获取联系方式
    const getPhone = async () => {
      try {
        const res = await API.getPhone();
        const { code, data = {} } = res;
        if (code === 200) {
          setPhone(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPhone();
  }, []);

  const handleItemClick = (id) => {
    props.history.push(`/quotesDetail/${id}`);
  };

  if (!list.length) {
    return null;
  }

  return (
    <div id="index-page" className="index-page">
      <Tabbar />
      <Navbar
        renderRight={() => (
          <div className="navbar-status">
            <span className={`navbar-time ${isOpening ? 'open' : ''}`}>
              {nowTime}
            </span>
            <span className={`navbar-open ${isOpening ? 'open' : ''}`}>
              {isOpening ? '开盘' : '闭盘'}
            </span>
          </div>
        )}
      />
      <div className="fixed-header">
        <div className="fixed-header-content">
          <div style={{ flex: 3.4 }}>商品</div>
          <div style={{ flex: 2.5 }}>卖出</div>
          <div style={{ flex: 2.5 }}>买入</div>
          <div style={{ flex: 2.5 }}>高/低</div>
          <div style={{ flex: 1.1 }}>详情</div>
        </div>
      </div>
      {list.length && list[0].init === 1 ? (
        list.map((item,i) => (
          <div
            onClick={() => handleItemClick(item.id)}
            className={`row row${i}`}
            key={item.id}
          >
            <div style={{ flex: 3.4 }} className="row-title row-div">
              {item.name}
            </div>
            <div className="row-item row-div" style={{ flex: 2.5 }}>
              {item.sellRise > 0 ? (
                <img
                  className="icon-raise"
                  src="http://www.shuibeidianjin.com/images/up.png"
                />
              ) : item.sellRise < 0 ? (
                <img
                  className="icon-raise"
                  src="http://www.shuibeidianjin.com/images/down.png"
                />
              ) : (
                ''
              )}
              <div
                className={`price  ${
                  item.sellCloseRise < 0
                    ? 'down'
                    : item.sellCloseRise > 0
                    ? 'up'
                    : 'equal'
                }`}
              >
                <span>{splitPrice(item.sellPrice, item.dec)[0]}</span>
                <span className="big">
                  {splitPrice(item.sellPrice, item.dec)[1]}
                </span>
              </div>
            </div>
            <div className="row-item row-div" style={{ flex: 2.5 }}>
              {item.buyRise > 0 ? (
                <img
                  className="icon-raise"
                  src="http://www.shuibeidianjin.com/images/up.png"
                />
              ) : item.buyRise < 0 ? (
                <img
                  className="icon-raise"
                  src="http://www.shuibeidianjin.com/images/down.png"
                />
              ) : (
                ''
              )}
              <div
                className={`price ${
                  item.buyCloseRise < 0
                    ? 'down'
                    : item.buyCloseRise > 0
                    ? 'up'
                    : 'equal'
                }`}
              >
                <span>{splitPrice(item.buyPrice, item.dec)[0]}</span>
                <span className="big">
                  {splitPrice(item.buyPrice, item.dec)[1]}
                </span>
              </div>
            </div>
            <div style={{ flex: 2.5 }} className="row-div">
              <div className="top-price">
              <span>{splitPrice(item.maxBuyPrice, item.dec)[0]}</span>
              <span className="big">
                {splitPrice(item.maxBuyPrice, item.dec)[1]}
              </span>
              </div>
              <div className="bottom-price">
              <span className={`green`}>
                {splitPrice(item.minBuyPrice, item.dec)[0]}
              </span>
              <span className={`big green`}>
                {splitPrice(item.minBuyPrice, item.dec)[1]}
              </span>
              </div>
            </div>
            <div style={{ flex: 1.1 }} className="price-icon">
              <img
                className="icon-detail"
                src="http://www.shuibeidianjin.com/images/detail.png?v=1"
              />
            </div>
          </div>
        ))
      ) : (
        <div className="index-page-loading">数据加载中...</div>
      )}
      {list.length && list[0].init === 1 ? (
        <div className="copyright">
          <p>声明:以上行情仅供参考！</p>
          <p>水贝点金热线：{phone}</p>
          <a target="black" href="http://www.beian.miit.gov.cn">
            Copyright © 2020 粤ICP备17112155号-1
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default Index;
