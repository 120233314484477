import React, { useEffect, useState } from 'react';
import { Map, Marker } from 'react-amap';
import API from './../../api';
import { Navbar, Tabbar } from './../../components';
import './style.less';

const Address = () => {
  const [phone, setPhone] = useState('');
  const position = [114.130716, 22.579785];

  useEffect(() => {
    // 获取联系方式
    const getPhone = async () => {
      try {
        const res = await API.getPhone();
        const { code, data = {} } = res;
        if (code === 200) {
          setPhone(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPhone();
  }, []);
  return (
    <div className="address-page">
      <Tabbar />
      <Navbar />
      <div className="info">
        <h3 className="title">深圳市水贝点金投资有限公司</h3>
        <p className="desc">
          主营业务：黄金，铂金，钯金，白银回购，销售，提纯，兑换板料
        </p>
        <p className="desc">地址：广东省深圳市罗湖区贝丽路水贝国际1608-1609</p>
        <p className="desc">手机：{phone}</p>
        <p className="desc">Email：2436137834@qq.com</p>
      </div>
      <div className="map-container">
        <div id="map">
          <Map
            amapkey={'2dd3b0e446f229def8c8d3e881a393b7'}
            center={position}
            zoom={18}
          >
            <Marker position={position} />
          </Map>
        </div>
      </div>
      <div className="qrcode">
        <div className="item">
          <img src="http://www.shuibeidianjin.com/images/gongzhonghao.png" />
          <p>
            添加二维码
            <br />
            关注公众号
          </p>
        </div>
        <div className="item">
          <img src="http://www.shuibeidianjin.com/images/minapp.png" />
          <p>
            水贝点金
            <br />
            小程序二维码
          </p>
        </div>
        <div className="item">
          <img src="http://www.shuibeidianjin.com/images/kefu.png" />
          <p>
            水贝点金
            <br />
            客服二维码
          </p>
        </div>
      </div>
    </div>
  );
};

export default Address;
