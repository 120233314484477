import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.less';

const Tabbar = (props) => {
  const { active } = props;
  return (
    <div className="tabbar-component">
      <div className="tabbar-content">
        <NavLink
          to="/index"
          activeClassName="selected"
          className={`item ${active === 1 ? 'selected' : ''}`}
        >
          <span>行情</span>
        </NavLink>
        <NavLink
          to="/info"
          activeClassName="selected"
          className={`item ${active === 2 ? 'selected' : ''}`}
        >
          <span>资讯</span>
        </NavLink>
        <NavLink
          to="/about"
          activeClassName="selected"
          className={`item ${active === 3 ? 'selected' : ''}`}
        >
          <span>公司介绍</span>
        </NavLink>
        <NavLink
          to="/address"
          activeClassName="selected"
          className={`item ${active === 4 ? 'selected' : ''}`}
        >
          <span>联系我们</span>
        </NavLink>
      </div>
    </div>
  );
};

export default React.memo(Tabbar);
