import axios from './axios';

const API = {
  // 行情
  latestMarketPrice() {
    return axios.get(
      'https://app2s.shuibeidianjin.com:444/api/app/jmf/DianJinApp/Quotations/QueryLatestMarketPrice'
    );
  },
  // 行情详情
  latestMarketPriceById(data) {
    return axios.post(
      'https://app2s.shuibeidianjin.com:444/api/app/jmf/DianJinApp/Quotations/QueryLatestMarketPriceById',
      data
    );
  },
  // k线图数据
  latestMarketTendencyForDay(data) {
    return axios.post(
      'https://app2s.shuibeidianjin.com:444/api/app/jmf/DianJinApp/Quotations/QueryLatestMarketTendencyForDay',
      data
    );
  },
  // 资讯
  information(params) {
    return axios.get(
      'https://min-app.shuibeidianjin.com:10101/api/sjdj-mp/information/websitePageInformation',
      params
    );
  },
  // 资讯详情
  informationDetail(id) {
    return axios.get(
      'https://min-app.shuibeidianjin.com:10101//api/sjdj-mp/information/websiteInformation',
      { id }
    );
  },
  getPhone() {
    return axios.get(
      'https://min-app.shuibeidianjin.com:10101/api/sjdj-mp/common/getPhone'
    );
  },
};

export default API;
