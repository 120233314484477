import React, { useState, useEffect, useRef } from 'react';
import echarts from 'echarts';
import { Navbar, Tabbar } from '../../components';
import API from '../../api';
import { useInterval } from '../../hooks';
import './style.less';

const formatNumber = (number, dec) => {
  if (!number) {
    return '';
  }
  return number.toFixed(dec);
};

function calculateMA(data, dayCount) {
  var result = [];
  for (var i = 0, len = data.values.length; i < len; i++) {
    if (i < dayCount) {
      result.push('-');
      continue;
    }
    var sum = 0;
    for (var j = 0; j < dayCount; j++) {
      sum += data.values[i - j][1];
    }
    result.push(sum / dayCount);
  }
  return result;
}

function formatData(data) {
  const categoryData = [];
  const values = [];
  for (let i = 0; i < data.length; i++) {
    const { time, openPrice, closePrice, lowestPrice, highestPrice } = data[i];
    categoryData.push(time.slice(5, -3));
    values.push([openPrice, closePrice, lowestPrice, highestPrice]);
  }
  return {
    categoryData: categoryData,
    values: values,
  };
}

const About = (props) => {
  const [id] = useState(props.match.params.id);
  const [detail, setDetail] = useState({});
  const [nowTime, setNowTime] = useState('');
  const [isOpening, setIsOpening] = useState(false);
  const [dec, setDec] = useState(2);
  const [startId, setStartId] = useState('');
  const [dataList, setDataList] = useState([]);
  const timer = useRef();

  useEffect(() => {
    const myChart = echarts.init(document.getElementById('kcharts'));
    myChart.on('dataZoom', (params) => {
      const { batch = [] } = params;
      // 防抖
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        if (batch[0] && batch[0].start < 5) {
          getLatestMarketTendencyForDay();
        }
      }, 1000);
    });

    window.onresize = () => {
      // 防抖
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        myChart.resize();
      }, 200);
    };

    const getLatestMarketTendencyForDay = async () => {
      try {
        const res = await API.latestMarketTendencyForDay({
          params: `{"id":"${id}","startId":"${startId}"}`,
        });
        const { data = {} } = res;
        if (data.success) {
          let result = [];
          if (!startId) {
            result = data.data;
          } else {
            result = [...data.data, ...dataList];
          }
          const list = formatData(result);
          let dataZoomEnd = 100 - (list.categoryData.length / 100) * 5;
          dataZoomEnd = dataZoomEnd < 50 ? 50 : dataZoomEnd;
          let dataZoomStart = dataZoomEnd - 30;
          myChart.setOption({
            baseOption: {
              backgroundColor: '#fff',
              color: ['#fbb37c', '#b2d155', '#77ccfb', '#c4a0f6'],
              grid: {
                left: '2%',
                right: '2%',
                containLabel: true,
              },
              legend: {
                data: [
                  { name: '日K' },
                  { name: 'MA5', icon: 'circle' },
                  { name: 'MA10', icon: 'circle' },
                  { name: 'MA20', icon: 'circle' },
                  { name: 'MA30', icon: 'circle' },
                ],
                inactiveColor: '#777',
                textStyle: {
                  color: '#9f9f9f',
                },
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  animation: false,
                  type: 'cross',
                  lineStyle: {
                    color: '#376df4',
                    width: 2,
                    opacity: 1,
                  },
                },
                formatter: function (params) {
                  var result = params[0].name + '<br/>';
                  params.forEach(function (item) {
                    if (item.seriesName == '日K') {
                      result +=
                        item.marker +
                        ' ' +
                        item.seriesName +
                        '  (' +
                        item.value.toString().split(',')[1] +
                        '，' +
                        +item.value.toString().split(',')[2] +
                        ')<br/>' +
                        '-开盘:' +
                        item.value[1] +
                        '<br/>' +
                        '-收盘:' +
                        item.value[2] +
                        '<br/>' +
                        '-最低:' +
                        item.value[3] +
                        '<br/>' +
                        '-最高:' +
                        item.value[4] +
                        '<br/>';
                    } else if (typeof item.value === 'number') {
                      result +=
                        item.marker +
                        ' ' +
                        item.seriesName +
                        ' : ' +
                        item.value.toFixed(dec) +
                        '<br/>';
                    }
                  });
                  return result;
                },
              },
              xAxis: {
                name: 'time',
                type: 'category',
                data: list.categoryData,
                axisLine: { lineStyle: { color: '#ededee' } },
                axisLabel: { color: '#8e8e93' },
              },
              yAxis: {
                scale: true,
                axisLine: { lineStyle: { color: '#ededee' } },
                splitLine: { show: false },
                axisLabel: { color: '#8e8e93' },
              },
              dataZoom: [
                {
                  type: 'inside',
                  start: dataZoomStart,
                  end: dataZoomEnd,
                },
              ],
              animation: true,
              series: [
                {
                  type: 'candlestick',
                  name: '日K',
                  data: list.values,
                  itemStyle: {
                    color: '#FD1050',
                    color0: '#0CF49B',
                    borderColor: '#FD1050',
                    borderColor0: '#0CF49B',
                  },
                },
                {
                  name: 'MA5',
                  type: 'line',
                  data: calculateMA(list, 5),
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 1,
                  },
                },
                {
                  name: 'MA10',
                  type: 'line',
                  data: calculateMA(list, 10),
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 1,
                  },
                },
                {
                  name: 'MA20',
                  type: 'line',
                  data: calculateMA(list, 20),
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 1,
                  },
                },
                {
                  name: 'MA30',
                  type: 'line',
                  data: calculateMA(list, 30),
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 1,
                  },
                },
              ],
            },
          });

          setStartId(data.data[0].id);
          setDataList(result);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (!dataList.length && !startId) {
      getLatestMarketTendencyForDay();
    }
  }, [id, dec, dataList, startId]);

  useEffect(() => {
    getLatestMarketPriceById();
    getLatestMarketPrice();
  }, [id]);

  useInterval(() => {
    getLatestMarketPrice();
    getLatestMarketPriceById();
  }, 1000);

  const getLatestMarketPrice = async () => {
    try {
      const res = await API.latestMarketPrice();
      const { data = {} } = res;
      if (data.success) {
        const { nowTime, isOpening } = data;
        setNowTime(nowTime);
        setIsOpening(isOpening);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLatestMarketPriceById = async () => {
    try {
      const res = await API.latestMarketPriceById({
        params: `{"id":"${id}"}`,
      });
      const { data = {} } = res;
      if (data.success) {
        const marketPrices = data.marketPrices[0];
        setDec(marketPrices.dec);
        setDetail((prevPrice) => {
          marketPrices.sellRise = marketPrices.sellPrice - prevPrice.sellPrice;
          marketPrices.buyRise = marketPrices.buyPrice - prevPrice.buyPrice;
          marketPrices.sellCloseRise =
            marketPrices.sellPrice - prevPrice.sellClosePrice;
          marketPrices.buyCloseRise =
            marketPrices.buyPrice - prevPrice.buyClosePrice;
          const { buyChangeAmount, buyChangeRate, dec } = marketPrices;
          let changeAmount = buyChangeAmount.toFixed(dec);
          marketPrices.changeAmount =
            changeAmount > 0 ? `+${changeAmount}` : changeAmount;
          let changeRate = buyChangeRate.toFixed(dec === 0 ? 2 : dec);
          marketPrices.changeRate =
            changeRate > 0 ? `+${changeRate}%` : `${changeRate}%`;

          return marketPrices;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="quotes-detail-page">
      <Navbar
        showBack
        renderRight={() => (
          <div className="navbar-status">
            <span className={`navbar-time ${isOpening ? 'open' : ''}`}>
              {nowTime}
            </span>
            <span className={`navbar-open ${isOpening ? 'open' : ''}`}>
              {isOpening ? '开盘' : '闭盘'}
            </span>
          </div>
        )}
      />
      <Tabbar active={1} />
      <div className="quotes-info">
        <p className="quotes-title">{detail.name}</p>
        <div className="quotes-detail">
          <div
            className={`quotes-left price ${
              detail.buyCloseRise < 0
                ? 'down'
                : detail.buyCloseRise > 0
                ? 'up'
                : ''
            }`}
          >
            <p>
              {formatNumber(detail.buyPrice, detail.dec)}
              {detail.buyRise > 0 ? (
                <img
                  className="icon-raise"
                  src="http://www.shuibeidianjin.com/images/up.png"
                />
              ) : detail.buyRise < 0 ? (
                <img
                  className="icon-raise"
                  src="http://www.shuibeidianjin.com/images/down.png"
                />
              ) : (
                ''
              )}
            </p>
            <p>
              {detail.changeAmount}/{detail.changeRate}
            </p>
          </div>
          <div className="quotes-right">
            <div className="quotes-row">
              <div className="quotes-item">
                <p>买入</p>
                <p
                  className={`price ${
                    detail.buyCloseRise < 0
                      ? 'down'
                      : detail.buyCloseRise > 0
                      ? 'up'
                      : ''
                  }`}
                >
                  {formatNumber(detail.buyPrice, detail.dec)}
                  {detail.buyRise > 0 ? (
                    <img
                      className="icon-raise"
                      src="http://www.shuibeidianjin.com/images/up.png"
                    />
                  ) : detail.buyRise < 0 ? (
                    <img
                      className="icon-raise"
                      src="http://www.shuibeidianjin.com/images/down.png"
                    />
                  ) : (
                    ''
                  )}
                </p>
              </div>
              <div className="quotes-item">
                <p>卖出</p>
                <p
                  className={`price ${
                    detail.sellCloseRise < 0
                      ? 'down'
                      : detail.sellCloseRise > 0
                      ? 'up'
                      : ''
                  }`}
                >
                  {formatNumber(detail.sellPrice, detail.dec)}
                  {detail.sellRise > 0 ? (
                    <img
                      className="icon-raise"
                      src="http://www.shuibeidianjin.com/images/up.png"
                    />
                  ) : detail.sellRise < 0 ? (
                    <img
                      className="icon-raise"
                      src="http://www.shuibeidianjin.com/images/down.png"
                    />
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </div>
            <div className="quotes-row">
              <div className="quotes-item">
                <p>最高</p>
                <p className="normal">
                  {formatNumber(detail.maxBuyPrice, detail.dec)}
                </p>
              </div>
              <div className="quotes-item">
                <p>最低</p>
                <p className="normal">
                  {formatNumber(detail.minBuyPrice, detail.dec)}
                </p>
              </div>
            </div>
            <div className="quotes-row">
              <div className="quotes-item">
                <p>今开</p>
                <p className="normal">
                  {formatNumber(detail.buyOpenPrice, detail.dec)}
                </p>
              </div>
              <div className="quotes-item">
                <p>昨收</p>
                <p className="normal">
                  {formatNumber(detail.buyClosePrice, detail.dec)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="charts-container">
        <div id="kcharts" />
      </div>
    </div>
  );
};

export default About;
